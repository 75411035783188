
import Vue from 'vue';

import ProgressBar from '@/components/ui/ProgressBar';

interface IProgressData {
  text: string,
  color: string
}

interface IDeadlineChipInfo {
  color: {
    icon: string,
    text: string,
  },
  text: string,
}

export default Vue.extend({
  name: 'TrackProgress',

  components: {
    ProgressBar,
  },

  props: {
    progress: {
      type: Number,
      required: true,
      validator(progress: number) {
        return progress >= 0 && progress <= 100;
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
    deadline: {
      type: String,
      default: '',
    },
  },

  computed: {
    progressData(): IProgressData {
      return this.loading
        ? {
          text: this.$t('TrackProgress.loadingText'),
          color: 'tt-light-mono-16',
        }
        : {
          text: `${this.$t('TrackProgress.learned')} ${this.progress}%`,
          color: this.isFailed ? 'tt-light-mono-16' : 'tt-color-green-400',
        };
    },

    isExpires(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.deadline);
      return deadline.diff(today, 'week') < 1;
    },

    isCompleted(): boolean {
      return this.progress === 100;
    },

    isFailed(): boolean {
      const today = this.$dayjs();
      const deadline = this.$dayjs(this.deadline);
      return !this.isCompleted && today.isAfter(deadline, 'days');
    },

    deadlineHumanized(): string {
      const deadline = this.$dayjs(this.deadline);

      if (deadline.isToday()) {
        return this.$t('AssignedTracksCardDeadline.completeToday');
      }
      if (deadline.isTomorrow()) {
        return this.$t('AssignedTracksCardDeadline.completeTomorrow');
      }
      return this.$t('AssignedTracksCardDeadline.completeBefore', { date: deadline.format('DD MMMM') });
    },

    info(): IDeadlineChipInfo {
      switch (true) {
        case this.isExpires:
          return {
            color: {
              icon: 'tt-light-red--text',
              text: 'tt-light-red--text',
            },
            text: this.deadlineHumanized,
          };
        default:
          return {
            color: {
              icon: 'tt-light-mono-24--text',
              text: 'tt-light-mono-100--text',
            },
            text: this.deadlineHumanized,
          };
      }
    },

    showDeadlineChip(): boolean {
      return Boolean(this.deadline) && !this.isCompleted && !this.isFailed;
    },
  },

});
