
import Vue, { PropType } from 'vue';

import { AtomType } from '@/domains/atom';

export default Vue.extend({
  name: 'TrackStepIcon',
  components: {},

  props: {
    materialType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    isFailed: {
      type: Boolean,
      default: false,
    },
    isWasted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    icon(): string {
      // if (this.isDisabled) return '$lock';

      switch (this.materialType) {
        case AtomType.MULTICONTENT:
          return '$play';
        case AtomType.SCORM:
          return '$play';
        case AtomType.MULTILEVELTEST:
          return '$questionSquare';
        case AtomType.QUIZ:
          return '$questionSquare';
        default:
          return '';
      }
    },

    iconStyles(): {} {
      switch (this.materialType) {
        case AtomType.MULTICONTENT:
          return { marginLeft: '3px' };
        case AtomType.SCORM:
          return { marginLeft: '3px' };
        case AtomType.MULTILEVELTEST:
          return { marginLeft: '1px' };
        case AtomType.QUIZ:
          return { marginLeft: '1px' };
        default:
          return {};
      }
    },

    iconColor(): string {
      return this.isDisabled || this.isPreview
        ? 'tt-color-bluegray-100--text'
        : 'tt-color-indigo-300--text';
    },

    badgeColor(): string {
      return this.isCompleted
        ? 'tt-white'
        : '';
    },
  },
});
