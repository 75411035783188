
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'TrackViewBannerCompleted',
  inject: ['Names'],
  setup() {
    return {
      show: ref<boolean>(true),
    };
  },
});
